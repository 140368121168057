<template>
	<div class="wrap wrap--products">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
            <div class="product-ban-container">
                <div class="img"><img src="/images/products/product-ban-brand.jpg" alt=""></div>
            </div>
			<div class="container">
				<div class="product-list-container">
					<!-- 브랜드 리스트 -->
					<section class="brand-lists">
                        <ul class="grid">
                            <li class="grid-8"><a class="link" href="/shop/brand-market/code"><img src="/images/sample/product-brand-logo.jpg" alt=""></a></li>
                            <li class="grid-8"><a class="link" href="/shop/brand-market/code"><img src="/images/sample/product-brand-logo.jpg" alt=""></a></li>
                            <li class="grid-8"><a class="link" href="/shop/brand-market/code"><img src="/images/sample/product-brand-logo.jpg" alt=""></a></li>
                            <li class="grid-8"><a class="link" href="/shop/brand-market/code"><img src="/images/sample/product-brand-logo.jpg" alt=""></a></li>
                            <li class="grid-8"><a class="link" href="/shop/brand-market/code"><img src="/images/sample/product-brand-logo.jpg" alt=""></a></li>
                            <li class="grid-8"><a class="link" href="/shop/brand-market/code"><img src="/images/sample/product-brand-logo.jpg" alt=""></a></li>
                            <li class="grid-8"><a class="link" href="/shop/brand-market/code"><img src="/images/sample/product-brand-logo.jpg" alt=""></a></li>
                            <li class="grid-8"><a class="link" href="/shop/brand-market/code"><img src="/images/sample/product-brand-logo.jpg" alt=""></a></li>
                            <li class="grid-8"><a class="link" href="/shop/brand-market/code"><img src="/images/sample/product-brand-logo.jpg" alt=""></a></li>
                            <li class="grid-8"><a class="link" href="/shop/brand-market/code"><img src="/images/sample/product-brand-logo.jpg" alt=""></a></li>
                            <li class="grid-8"><a class="link" href="/shop/brand-market/code"><img src="/images/sample/product-brand-logo.jpg" alt=""></a></li>
                            <li class="grid-8"><a class="link" href="/shop/brand-market/code"><img src="/images/sample/product-brand-logo.jpg" alt=""></a></li>
                        </ul>
                    </section>
				</div>
			</div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from "../../../components/client/inc/main-header.vue";
import MainFooter from "../../../components/client/inc/main-footer.vue";

export default{
	components: {
		MainHeader,
		MainFooter
	},
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
}
</script>
