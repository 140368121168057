var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--products"
  }, [_c('main-header'), _vm._m(0), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "product-ban-container"
  }, [_c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": "/images/products/product-ban-brand.jpg",
      "alt": ""
    }
  })])]), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "product-list-container"
  }, [_c('section', {
    staticClass: "brand-lists"
  }, [_c('ul', {
    staticClass: "grid"
  }, [_c('li', {
    staticClass: "grid-8"
  }, [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "/shop/brand-market/code"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sample/product-brand-logo.jpg",
      "alt": ""
    }
  })])]), _c('li', {
    staticClass: "grid-8"
  }, [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "/shop/brand-market/code"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sample/product-brand-logo.jpg",
      "alt": ""
    }
  })])]), _c('li', {
    staticClass: "grid-8"
  }, [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "/shop/brand-market/code"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sample/product-brand-logo.jpg",
      "alt": ""
    }
  })])]), _c('li', {
    staticClass: "grid-8"
  }, [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "/shop/brand-market/code"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sample/product-brand-logo.jpg",
      "alt": ""
    }
  })])]), _c('li', {
    staticClass: "grid-8"
  }, [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "/shop/brand-market/code"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sample/product-brand-logo.jpg",
      "alt": ""
    }
  })])]), _c('li', {
    staticClass: "grid-8"
  }, [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "/shop/brand-market/code"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sample/product-brand-logo.jpg",
      "alt": ""
    }
  })])]), _c('li', {
    staticClass: "grid-8"
  }, [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "/shop/brand-market/code"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sample/product-brand-logo.jpg",
      "alt": ""
    }
  })])]), _c('li', {
    staticClass: "grid-8"
  }, [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "/shop/brand-market/code"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sample/product-brand-logo.jpg",
      "alt": ""
    }
  })])]), _c('li', {
    staticClass: "grid-8"
  }, [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "/shop/brand-market/code"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sample/product-brand-logo.jpg",
      "alt": ""
    }
  })])]), _c('li', {
    staticClass: "grid-8"
  }, [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "/shop/brand-market/code"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sample/product-brand-logo.jpg",
      "alt": ""
    }
  })])]), _c('li', {
    staticClass: "grid-8"
  }, [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "/shop/brand-market/code"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sample/product-brand-logo.jpg",
      "alt": ""
    }
  })])]), _c('li', {
    staticClass: "grid-8"
  }, [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "/shop/brand-market/code"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sample/product-brand-logo.jpg",
      "alt": ""
    }
  })])])])])])])]);

}]

export { render, staticRenderFns }