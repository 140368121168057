var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--products"
  }, [_c('main-header'), _vm._m(0), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "product-ban-container"
  }, [_c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": "/images/products/product-ban-brand.jpg",
      "alt": ""
    }
  })])]), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "product-main-title"
  }, [_c('h1', {
    staticClass: "text"
  }, [_vm._v("내추럴발란스")])]), _c('div', {
    staticClass: "product-list-container"
  }, [_c('section', {
    staticClass: "product-lists"
  }, [_c('div', {
    staticClass: "product-lists__head"
  }, [_c('div', {
    staticClass: "product-lists__order"
  }, [_c('div', {
    staticClass: "product-lists__group"
  }, [_c('ul', {
    staticClass: "product-lists__group-lists"
  }, [_c('li', [_c('a', {
    staticClass: "on",
    attrs: {
      "href": ""
    }
  }, [_vm._v("높은 가격순")])]), _c('li', [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("낮은 가격순")])])])]), _c('div', {
    staticClass: "product-lists__select"
  }, [_c('select', {
    staticClass: "product-lists__select-option",
    attrs: {
      "name": "",
      "id": ""
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("20개 보기")])])])])]), _c('div', {
    staticClass: "product-lists__body"
  }, [_c('div', {
    staticClass: "grid grid--col-4"
  }, [_c('div', {
    staticClass: "grid-6"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])])])]), _c('div', {
    staticClass: "grid-6"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])])])]), _c('div', {
    staticClass: "grid-6"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])])])]), _c('div', {
    staticClass: "grid-6"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])])])]), _c('div', {
    staticClass: "grid-6"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])])])]), _c('div', {
    staticClass: "grid-6"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])])])]), _c('div', {
    staticClass: "grid-6"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])])])]), _c('div', {
    staticClass: "grid-6"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])])])]), _c('div', {
    staticClass: "grid-6"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])])])]), _c('div', {
    staticClass: "grid-6"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])])])]), _c('div', {
    staticClass: "grid-6"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])])])]), _c('div', {
    staticClass: "grid-6"
  }, [_c('a', {
    staticClass: "product-list",
    attrs: {
      "href": "/shop/products/view"
    }
  }, [_c('span', {
    staticClass: "product-list__thumb"
  }, [_c('img', {
    staticClass: "product-list__thumbnail",
    attrs: {
      "src": "/images/sample/product-thumbnail-medium.jpg",
      "alt": ""
    }
  })]), _c('span', {
    staticClass: "product-list__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('span', {
    staticClass: "product-list__price"
  }, [_c('span', {
    staticClass: "product-list__price-left"
  }, [_c('em', {
    staticClass: "product-list__price-num"
  }, [_vm._v("15,000")]), _vm._v("원 "), _c('span', {
    staticClass: "product-list__price-percent"
  }, [_vm._v("25%")])])])])])]), _c('div', {
    staticClass: "product-lists__pagination"
  }, [_c('div', {
    staticClass: "pagination"
  }, [_c('a', {
    staticClass: "pagination__backward",
    attrs: {
      "href": ""
    }
  }), _c('a', {
    staticClass: "pagination__prev",
    attrs: {
      "href": ""
    }
  }), _c('a', {
    staticClass: "current"
  }, [_vm._v("1")]), _c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("2")]), _c('a', {
    staticClass: "pagination__next",
    attrs: {
      "href": ""
    }
  }), _c('a', {
    staticClass: "pagination__forward",
    attrs: {
      "href": ""
    }
  })])])])])])])]);

}]

export { render, staticRenderFns }